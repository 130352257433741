<template>
  <el-card>
    <div slot="header">
      <h3>版权声明</h3>
    </div>

    <ol>
      <li>任何媒体、网站、机构或个人未经本网站在线授权，不得转载、链接或以其他方式复制、发布、发表。</li>
      <li>在线发布的，用户署名的作品/内容，著作权由用户本人享有，任何媒体、网站、机构或个人未经该用户授权，不得转载、链接或以其他方式复制、发布、发表。</li>
      <li>转载或引用医生在线上发布的原创作品/内容，应获得医生授权，以显著方式注明医生姓名和医生个人网站链接，并按照法律规定向医生支付稿酬。</li>
      <li>以《中华人民共和国著作权法》第二十二条许可的方式“合理使用”好大夫在线上发布的任何内容/作品，均应以显著方式注明来源，或以其他公开方式说明内容/作品来源。</li>
      <li>无论以“合理使用”或“法定许可”方式转载在线上发布的任何作品/内容，不得对作品/内容进行曲解、改编、夸大，不得损害在线/或原作者对作品/内容的合法权益。</li>
      <li>在线发布的以下作品/内容不得转载或以其他任何方式使用：
        <ul>
          <li>在线展示的医患之间对具体病情的交流；</li>
          <li>在线或作品发布者已声明“不得转载”的作品/内容；</li>
          <li>在线的注册商标、特有标志、页面风格、编排方式、程序等；</li>
          <li>在线中必须具有特别授权方可知晓的作品/内容；</li>
          <li>其他法律不允许或好大夫在线认为不适合转载的作品/内容。</li>
        </ul>
      </li>
      <li>对于不当转载在线发布的第三方作品/内容而引起的民事纠纷、行政处罚或其他法律或经济责任，好大夫在线不承担责任。</li>
      <li>对不遵守本声明或其他违法、恶意的转载、使用行为，在线保留追究其法律责任的权利。</li>
    </ol>
  </el-card>
</template>

<script>
  export default {
    name: 'CopyrightIndex',
    data() {
      return {}
    }
  }
</script>

<style scoped lang="scss">

  .el-card {
    min-height: 1050px;

    .el-card__header {
      h3 {
        margin: 0;
        padding: 0;
      }
    }

    li {
      margin: 10px;
    }
  }

</style>
